<template>
  <div class="votePeopleList">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
    </v-list>
  </div>
</template>

<script>
import { getTopicAnswerListlURL } from "./api.js";
import { createImgVNode } from "@/utils/utils.js";

export default {
  name: "votePeopleList",
  data() {
    return {
      searchParam: {
        communityId: null,
        topicId: undefined,
      },
      tableUrl: getTopicAnswerListlURL,
      headers: [
        // {
        //   prop: "communityName",
        //   label: "园区",
        // },
        {
          prop: "userAvatar",
          label: "用户头像",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "userName",
          label: "用户姓名",
        },
        {
          prop: "intime",
          label: "参与时间",
        },
      ],
    };
  },
  computed: {
    // createTime: {
    //   get() {
    //     return !this.searchParam.createTimeS && !this.searchParam.createTimeE
    //       ? []
    //       : [this.searchParam.createTimeS, this.searchParam.createTimeE];
    //   },
    //   set(val) {
    //     [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
    //       "",
    //       "",
    //     ];
    //   },
    // },
  },
  created() {
    this.searchParam.topicId = this.$route.query.id;
    this.$setBreadList("问卷回答人");
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.votePeopleList {
  // box-sizing: border-box;
  height: 100%;
}
</style>
